import axios from "axios";
import Cookie from "js-cookie";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    accept: "application/vnd.socialcentiv.v2",
  },
});

// interceptor to set or update the headers before each request
instance.interceptors.request.use(
  function (config) {
    const userEmail = Cookie.get("UserEmail");
    const userToken = Cookie.get("UserAuth");

    config.headers["X-User-Email"] = userEmail;
    config.headers["X-User-Token"] = userToken;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;
